<template>
  <nav>
    <v-app-bar elevate-on-scroll dense app color="white">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer floating app v-model="drawer">
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-avatar color="indigo accent-2">
              <v-icon left color="white">mdi-account</v-icon>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{userdata.name}}</v-list-item-title>
            <v-list-item-subtitle class="font-italic">Logged In as {{userdata.role}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      

      <v-subheader>User Menu</v-subheader>
      <v-list nav>
        <v-list-item link v-for="(link, i) in user" :key="i" :to="link.to">
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      

      <v-subheader>Admin Menu</v-subheader>
      <v-list nav>
        <v-list-item link v-for="(link, i) in admin" :key="i" :to="link.to">
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-col>
          <v-btn block :loading="loading" :disabled="loading" color="error" @click="handleLogout">
            <span>logout</span>
            <v-icon right>mdi-logout</v-icon>
          </v-btn>
        </v-col>
      </template>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  data() {
    return {
      drawer: true,
      loading: false,
      userdata: {
        name: null,
        role: null
      },
      user: [
        { title: "Dashboard", icon: "mdi-view-dashboard", to: "dashboard" },
        { title: "Member", icon: "mdi-account-group", to: "members" },
        { title: "Session", icon: "mdi-calendar-check", to: "sessions" },
      ],
      admin: [
        { title: "Users", icon: "mdi-account-multiple", to: "users" },
        { title: "Agents", icon: "mdi-face-agent", to: "agents" },
      ],
    };
  },
  methods: {
    ...mapActions(["logout"]),
    handleLogout(){
      this.loading = true
      this.logout().then(()=>{
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('name')
        window.localStorage.removeItem('role')
        this.loading = false
      }).then(() => {
        this.$router.push({
          name: 'Login',
          path: '/login',
          query: {
            redirect: this.$route.path,
            message: 'You have been logged out'
          }
        })
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    }
  },
  created(){
    this.userdata.name = window.localStorage.getItem('name')
    this.userdata.role = window.localStorage.getItem('role')
  }
};
</script>